window.addEventListener("load", function () {
    fetch('index.html')
        .then(response => response.text())
        .then(html => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');

            // Select the master list element in the index.html
            const masterList = doc.querySelector('#masterList');
            
            if (masterList) {
                // Find all elements with id containing "listPlaceholder"
                const placeholders = document.querySelectorAll('[id*="listPlaceholder"]');
                
                placeholders.forEach(placeholder => {
                    // Clear existing content
                    placeholder.innerHTML = '';
                    // Append a clone of the master list (including the <ul> tag)
                    placeholder.appendChild(masterList.cloneNode(true));
					placeholder.querySelector('ul').removeAttribute('id');
                });
            }
        })
        .catch(error => console.error('Error loading master list:', error));
});
